import React from "react";

export const Logo = () => {
  return (
    <svg width="71" height="36" viewBox="0 0 71 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.2629 17.0557C66.6428 17.0557 70.9995 21.2804 70.9995 26.4952C70.9995 31.71 66.6428 35.9347 61.2629 35.9347C55.8831 35.9347 51.5264 31.71 51.5264 26.4952C51.5264 21.2804 55.8831 17.0557 61.2629 17.0557ZM61.2629 32.3702C64.4645 32.3702 67.0389 29.7297 67.0389 26.4952C67.0389 23.2607 64.4645 20.6202 61.2629 20.6202C58.0614 20.6202 55.487 23.2607 55.487 26.4952C55.487 29.7297 58.0614 32.3702 61.2629 32.3702Z"
        fill="#2B2D33"/>
      <path
        d="M39.7058 35.8615H35.7451V12.0977H39.7058V25.0028L46.0428 18.0386H51.0926L43.8974 25.7949L52.7429 35.8615H47.594L39.7058 26.62V35.8615Z"
        fill="#2B2D33"/>
      <path
        d="M29.0006 35.8041V26.0015C29.0006 23.2621 26.8552 21.0177 24.1818 21.0177C21.5083 21.0177 19.363 23.2621 19.363 26.0015V35.8041H15.4023V26.2326C15.4023 21.3808 19.33 17.4531 24.1818 17.4531C29.0336 17.4531 32.9612 21.3808 32.9612 26.2326V35.8041H29.0006Z"
        fill="#2B2D33"/>
      <path
        d="M12.4588 13.6811C12.4588 15.0013 11.4027 16.0575 10.0824 16.0575C8.76223 16.0575 7.70605 15.0013 7.70605 13.6811C7.70605 12.3609 8.76223 11.3047 10.0824 11.3047C11.4027 11.3047 12.4588 12.3609 12.4588 13.6811ZM12.0628 18.0378V35.8607H8.10212V18.0378H12.0628Z"
        fill="#2B2D33"/>
      <path d="M3.96064 12.0977V35.8615H0V12.0977H3.96064Z" fill="#2B2D33"/>
      <path
        d="M12.7539 9.20996L8.8623 9.20996C11.7597 3.7186 17.4977 0.000491718 24.1447 0.000491428C30.7633 0.000491139 36.5297 3.7186 39.4271 9.20996L35.5355 9.20996C33.0073 5.72066 28.7748 3.43259 24.1447 3.43259C19.5145 3.43259 15.2536 5.72066 12.7539 9.20996Z"
        fill="#4243FF"/>
    </svg>

  )
}
