import React from "react";

export const Right = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12.9346H19" stroke="#F7F8FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 5.93457L19 12.9346L12 19.9346" stroke="#F7F8FA" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  )
}
