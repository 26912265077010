import React, {useState} from "react";
import axios from "../tools/axios";
import styled from "styled-components";
import {Logo} from "../icons/components/logo";
import {Client} from "../icons/components/client";
import {Right} from "../icons/components/right";
import {Instagram} from "../icons/components/instagram";
import Loader from "../components/Loader";

const Wrapper = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 120px 130px;
    background: linear-gradient(360deg, rgba(39, 40, 128, 0.26) 0%, rgba(203, 203, 241, 0.4) 100%);
    z-index: 2;

    @media (max-width: 1050px) {
        input {
            font-size: 14px;
        }
    }

    @media (max-width: 950px) {
        padding: 22px 60px 80px;
    }

    @media (max-width: 550px) {
        padding: 16px;
    }
    
`

const Background = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url('/background.png'); /* Set the image path */
    background-size: cover; /* Ensure the image covers the entire element */
    background-position: center center; /* Center the image */
    background-repeat: no-repeat;
`

const WrapContent = styled('div')`
    z-index: 2;
    width: 1200px;
    height: 100%;

    @media (max-width: 1250px) {
        width: 100%;
    }
`

const Top = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Phone = styled('div')`
    background: #fff;
    border-radius: 16px;
    padding: 12px 16px;
    color: #2B2D33;
    font-weight: 400;
`

const BorderLine = styled('div')`
    border: 1px solid #fff;
    opacity: 50%;
    margin: 30px 0;

    @media (max-width: 550px) {
        margin: 11px 0;
    }
`

const ContentBox = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
`

const Soon = styled('div')`
    background: #272980;
    border-radius: 24px;
    width: 100%;
    padding: 28px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1050px) {
        input {
            font-size: 14px;
        }
    }

    @media (max-width: 950px) {
        flex-direction: column;
        align-items: self-start;
    }

    @media (max-width: 550px) {
        padding: 16px;
    }
`

const SoonTitle = styled('p')`
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    width: 47%;
    color: #fff;

    @media (max-width: 1050px) {
        font-size: 28px;
        line-height: 34px;
    }

    @media (max-width: 950px) {
        width: 100%;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`

const Form = styled('form')`
    width: 50%;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    gap: 20px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #fff;
    }

    @media (max-width: 950px) {
        width: 100%;
        button {
            width: 100%;
        }
    }
`

const Inputs = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 950px) {
        flex-direction: column;
    }
`

const Input = styled('input')`
    border-radius: 8px;
    outline: none;
    padding: 10px;
    width: 100%;
    height: 45px;
    border: none;
`

const Button = styled('button')`
    min-width: 180px;
    height: 45px;
    background: #2055E5;
    padding: 12px 55px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    
`

const ContentFlex = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`

const Vacation = styled('div')`
    height: 290px;
    width: 40%;
    background: #fff;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    align-items: start;
    gap: 20px;
    
    .left {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    p {
        color: #555A65;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 1400px) {
        .client {
            width: 160px;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media (max-width: 1300px) {
        .left {
            width: 100%;
        }
        .client {
            display: none;
        }
    }

    @media (max-width: 1200px) {
        width: 100%;
        .client {
            display: block;
            width: 40%;
        }
    }

    @media (max-width: 950px) {
        height: 100%;

        .client {
            display: none;
        }

        p {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 550px) {
        padding: 16px;
        gap: 0;

        a {
            width: 100%;
        }
        
        button {
            width: 100%;
        }
    }
`

const Description = styled('div')`
    height: 290px;
    width: 60%;
    background: #fff;
    border-radius: 24px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    position: relative;

    .left {
        width: 60%;
        height: 100%;
        padding: 24px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    p {
        color: #555A65;
        font-size: 15px;
    }

    .person {
        position: relative;
        right: 24px;
        bottom: -10px;
    }
    
    .border-line {
        width: 100%;
        margin: 8px 0;
        border: 1px solid #D7DAE2;
    }

    @media (max-width: 1400px) {
        p {
            font-size: 13px;
        }
    }

    @media (max-width: 1200px) {
        width: 100%;

        p {
            font-size: 14px;
        }
    }

    @media (max-width: 950px) {

        .left {
            width: 100%;
        }

        flex-direction: column;
        height: 100%;
        align-items: center;

        .person {
            bottom: 0;
        }
    }

    @media (max-width: 550px) {
        padding: 16px;
        
        .left {
            padding: 16px;
        }

        .person {
            bottom: -16px;
            right: 0;
        }
    }
`

const Home = () => {
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState({})
  const [number, setNumber] = useState('');

  const handleChangeNumber = (e) => {
    const value = e.target.value;

    if (/^\d{0,12}$/.test(value)) {
      setNumber(value);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    const {name} = values
    e.preventDefault()
    await axios().post('client/application/', {'full_name': name, 'phone_number': number, is_linko: true})
      .then(() => {
        setValues({ name: '', phone: '' })
        setNumber('')
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Wrapper>
      <Background />
      <WrapContent>
        <Top>
          <Logo />
          <Phone>
            +998 78 555-55-25
          </Phone>
        </Top>
        <BorderLine />
        <ContentBox>
          <Soon>
            <SoonTitle>
              Скоро откроется новый этап в автоматизации!
            </SoonTitle>
            <Form onSubmit={handleSubmit}>
              <p>Оставьте свои контакты, и мы сообщим вам о запуске!</p>
              <Inputs>
                <Input
                  name={'name'}
                  value={values.name}
                  placeholder={'Ваше имя'}
                  onChange={handleChange}
                />
                <Input
                  name={'phone'}
                  type={'number'}
                  placeholder={'Номер телефона'}
                  value={number}
                  onChange={handleChangeNumber}
                />
              </Inputs>
              <Button type={'submit'}>
                {loading ? <Loader /> : 'Отправить'}
              </Button>
            </Form>
          </Soon>
          <ContentFlex>
            <Vacation>
              <div className={'left'}>
                <div>
                  <h2 style={{marginBottom: '5px'}}>Вакансии</h2>
                  <p>Присоединяйтесь к нашей команде! Мы ищем талантливых специалистов,
                    готовых развиваться вместе с нами и создавать инновационные решения для автоматизации.</p>
                </div>
                <a href={'https://tashkent.hh.uz/employer/11553058?hhtmFrom=vacancy_search_list'} target={'_blank'}>
                  <Button>
                    Посмотреть
                    <Right />
                  </Button>
                </a>
              </div>
              <div className={'client'}>
                <Client />
              </div>
            </Vacation>
            <Description>
              <div className={'left'}>
                <p>
                  Софт, который поменяет правила игры на рынке автоматизации ритейла.
                  Мы вложили в него весь свой опыт и экспертизу, чтобы наши пользователи стали увереннее в себе.
                </p>
                <div style={{width: '100%'}}>
                  <h2>Лазиз Адхамов</h2>
                  <div className={'border-line'}/>
                  <p style={{fontSize: '14px', color: '#2055E5'}} className={'info'}>Основатель - SAP Uzbekistan, Book
                    Cafe,
                    Top Contact</p>
                  <div style={{display: 'flex', gap: '5px', marginTop: '8px'}}>
                    <Instagram />
                    <a
                      style={{fontSize: '14px', color: '#2B2D33'}}
                      target={'_blank'}
                      href={'https://www.instagram.com/dervish1976/'}>
                      dervish1976
                    </a>
                  </div>
                </div>
              </div>
              <img className={'person'} src={'/person.png'} alt={'Лазиз Адхамов'}/>
            </Description>
          </ContentFlex>
        </ContentBox>
      </WrapContent>
    </Wrapper>
  )
}

export default Home
